import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../components/templates/layout"
import DiscountPage from "../../components/views/retail/discounts"

const Discount = () => {
  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <Layout>
          <DiscountPage />
        </Layout>
      </DndProvider>
    </React.Fragment>
  )
}

export default Discount
